import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import CookieConsent from "react-cookie-consent";
import Cookies from 'js-cookie';
import questions from "./data/questions.json";
import civicInfo from "./data/civicinfo.json";
import "bootstrap/dist/css/bootstrap.min.css";
import "./App.css";

function App() {
  const [currentQuestion, setCurrentQuestion] = useState(null as any);
  const [gaInitialized, setGaInitialized] = useState(false);
  const [reveal, setReveal] = useState(false);
  const [questionScores, setQuestionScores] = useState(
    new Map<number, string[]>()
  );
  const [questionAndAnswers, setQuestionAndAnswers] = useState(
    new Map<string, string[]>()
  );
  const [loadedCivicInfo, setLoadedCivicInfo] = useState(civicInfo);
  const [stateOrTerritory, setStateOrTerritory] = useState("N/A" as string);
  const civicInfoUrl =
    "https://civicinfo.blob.core.windows.net/dynamic/civicinfo.json";

  const SENATE_QUESTION = "Who is one of your state's U.S. Senators now?";
  const HOUSE_QUESTION = "Name your U.S. Representative.";
  const GOVERNOR_QUESTION = "Who is the Governor of your state now?";
  const CAPITAL_QUESTION = "What is the capital of your state?";
  const PRESIDENT_QUESTION =
    "What is the name of the President of the United States now?";
  const VP_QUESTION =
    "What is the name of the Vice President of the United States now?";
  const JUSTIC_QUESTION = "How many justices are on the Supreme Court?";
  const CHIEF_JUSTICE_QUESTION =
    "Who is the Chief Justice of the United States now?";
  const PARTY_QUESTION = "What is the political party of the President now?";
  const SPEAKER_QUESTION =
    "What is the name of the Speaker of the House of Representatives now?";

  const noRepresentationTerritories = [
    "District Of Columbia",
    "American Samoa",
    "Guam",
    "Northern Mariana Islands",
    "Puerto Rico",
    "U.S. Virgin Islands",
  ];

  const stateMap: Record<string, string> = {
    Alabama: "AL",
    Alaska: "AK",
    Arizona: "AZ",
    Arkansas: "AR",
    California: "CA",
    Colorado: "CO",
    Connecticut: "CT",
    Delaware: "DE",
    Florida: "FL",
    Georgia: "GA",
    Hawaii: "HI",
    Idaho: "ID",
    Illinois: "IL",
    Indiana: "IN",
    Iowa: "IA",
    Kansas: "KS",
    Kentucky: "KY",
    Louisiana: "LA",
    Maine: "ME",
    Maryland: "MD",
    Massachusetts: "MA",
    Michigan: "MI",
    Minnesota: "MN",
    Mississippi: "MS",
    Missouri: "MO",
    Montana: "MT",
    Nebraska: "NE",
    Nevada: "NV",
    "New Hampshire": "NH",
    "New Jersey": "NJ",
    "New Mexico": "NM",
    "New York": "NY",
    "North Carolina": "NC",
    "North Dakota": "ND",
    Ohio: "OH",
    Oklahoma: "OK",
    Oregon: "OR",
    Pennsylvania: "PA",
    "Rhode Island": "RI",
    "South Carolina": "SC",
    "South Dakota": "SD",
    Tennessee: "TN",
    Texas: "TX",
    Utah: "UT",
    Vermont: "VT",
    Virginia: "VA",
    Washington: "WA",
    "West Virginia": "WV",
    Wisconsin: "WI",
    Wyoming: "WY",
    "District of Columbia": "DC",
    Guam: "GU",
    "Puerto Rico": "PR",
    "Virgin Islands": "VI",
    // Add more state/territory mappings here...
  };

  const handleReveal = () => {
    setReveal(true);
    if (Cookies.get('CitizenTestPrepCookieConsent') === 'true' && gaInitialized) {
    ReactGA.event({
      category: 'User',
      action: 'Clicked Reveal'
    });
  }
  };

  useEffect(() => {
    if (Cookies.get('CitizenTestPrepCookieConsent') === 'true') {
      ReactGA.initialize("G-0TZ86H2EP1");
      setGaInitialized(true);
      ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search, title: "Page Load" });
      // ReactGA.pageview(window.location.pathname + window.location.search);
    }
   
    loadCivicInfo();
    loadQuestionsAndScores();
  }, []);

  const loadCivicInfo = async () => {
    const lastCheck = localStorage.getItem("lastCheck");
    const currentTime = new Date().getTime();

    if (!lastCheck || currentTime - Number(lastCheck) > 24 * 60 * 60 * 1000) {
      // More than 24 hours since last check or no check has been made yet
      try {
        const startTime = new Date().getTime();
        const response = await fetch(civicInfoUrl);
        const data = await response.json();
        const endTime = new Date().getTime();
        const duration = endTime - startTime; // Duration of the fetch call in milliseconds

        setLoadedCivicInfo(data);
        localStorage.setItem("loadedCivicInfo", JSON.stringify(data));
        localStorage.setItem("lastCheck", String(currentTime));
        if (Cookies.get('CitizenTestPrepCookieConsent') === 'true' && gaInitialized) {
        ReactGA.send({ 
          hitType: "timing",
          timingCategory: 'Civic Info',
          timingVar: 'Fetch',
          timingValue: duration,
        });
      }
      } catch (error) {
        console.error("Failed to fetch civic info:", error);
        if (Cookies.get('CitizenTestPrepCookieConsent') === 'true' && gaInitialized) {
          ReactGA.event({
            category: 'Exception',
            action: 'Fetch civic info',
            label: error as string,
          });
      }
      }
    } else {
      // Less than 24 hours since last check, load data from local storage
      const savedData = localStorage.getItem("loadedCivicInfo");
      if (savedData) {
        setLoadedCivicInfo(JSON.parse(savedData));
      }
    }
  };

  const loadQuestionsAndScores = () => {
    const questionScoresSaved = localStorage.getItem("questionScores");
    var questionScoresToLoad = new Map<number, string[]>();
    if (questionScoresSaved && questionScoresSaved != "{}") {
      // setQuestionScores(JSON.parse(questionScoresSaved));
      const parsedData = JSON.parse(questionScoresSaved);
      const entries = Object.entries(parsedData).map(([key, value]) => [
        Number(key),
        value,
      ]);
      questionScoresToLoad = new Map(entries as any);
      setQuestionScores(questionScoresToLoad);
    } else {
      // for each question in questions, add it to the map with a score of 1
      var array: string[] = [];
      questions.forEach((question) => {
        array.push(question.question);
      });
      questionScoresToLoad.set(1, array);
      setQuestionScores(questionScoresToLoad);
      localStorage.setItem(
        "questionScores",
        JSON.stringify(Object.fromEntries(questionScoresToLoad))
      );
    }

    const questsionAndAnswersToLoad = new Map<string, string[]>();
    questions.forEach((question) => {
      questsionAndAnswersToLoad.set(question.question, question.answers);
    });
    setQuestionAndAnswers(questsionAndAnswersToLoad);

    const currentQuestionToLoad = localStorage.getItem("currentQuestion");
    if (currentQuestionToLoad && currentQuestionToLoad != "{}") {
      setCurrentQuestion(JSON.parse(currentQuestionToLoad));
    } else {
      for (var i = 1; i <= 5; i++) {
        var stringArray = questionScoresToLoad.get(i);
        if (stringArray && stringArray.length > 0) {
          var questionToSet = stringArray.shift();
          if (questionToSet) {
            var questionAndAnswer = {
              question: questionToSet,
              answers: questsionAndAnswersToLoad.get(questionToSet)!,
            };
            setCurrentQuestion(questionAndAnswer);
            localStorage.setItem(
              "currentQuestion",
              JSON.stringify(questionAndAnswer)
            );
            questionScoresToLoad.set(i, stringArray);
            setQuestionScores(questionScoresToLoad);
            localStorage.setItem(
              "questionScores",
              JSON.stringify(Object.fromEntries(questionScoresToLoad))
            );
            break;
          }
        }
      }
    }

    const savedState = localStorage.getItem("savedState");
    if (savedState) {
      setStateOrTerritory(savedState);
    }
  };

  // const getNextQuestion = () :any => {

  // }

  const handleScore = (score: number) => {
    // TODO: add the question to the map with the score
    const question = currentQuestion?.question;
    var stringArray = questionScores.get(score);
    if (stringArray) {
      stringArray.push(question);
    } else {
      stringArray = [question];
    }
    questionScores.set(score, stringArray);
    localStorage.setItem(
      "questionScores",
      JSON.stringify(Object.fromEntries(questionScores))
    );
    setQuestionScores(questionScores);
    setReveal(false);

    if (Cookies.get('CitizenTestPrepCookieConsent') === 'true' && gaInitialized) {
    ReactGA.event({
      category: 'User',
      action: 'Submitted Score',
      label: question,
      value: score,
    });
  }

    // Get next current question
    // getNextQuestion();
    var foundQuestion = false;
    for (var i = 1; i <= 5; i++) {
      var stringArray = questionScores.get(i);
      if (stringArray && stringArray.length > 0) {
        var questionToSet = stringArray.shift();
        if (questionToSet) {
          var questionAndAnswer = {
            question: questionToSet,
            answers: questionAndAnswers.get(questionToSet)!,
          };
          setCurrentQuestion(questionAndAnswer);
          localStorage.setItem(
            "currentQuestion",
            JSON.stringify(questionAndAnswer)
          );
          questionScores.set(i, stringArray);
          setQuestionScores(questionScores);
          localStorage.setItem(
            "questionScores",
            JSON.stringify(Object.fromEntries(questionScores))
          );
          foundQuestion = true;
          break;
          // return questionAndAnswer;
        }
      }
    }
    // TODO: if its all in too easy, maybe notify them?
  };

  const getAnswersForQuestion = (currentQ: any): string[] => {
    const question = currentQ.question;
    if (question === SENATE_QUESTION) {
      if (noRepresentationTerritories.includes(stateOrTerritory)) {
        return ["You have no representation in the Senate"];
      }
      if (stateOrTerritory === "N/A") {
        return ["Please select a state or territory"];
      }
      const stateCode = stateMap[stateOrTerritory].toLocaleLowerCase();
      return [...(loadedCivicInfo.stateSenators as any)[stateCode]];
    } else if (question === HOUSE_QUESTION) {
      if (noRepresentationTerritories.includes(stateOrTerritory)) {
        return ["You have no representation in the House of Representatives"];
      }
      if (stateOrTerritory === "N/A") {
        return ["Please select a state or territory"];
      }
      const stateCode = stateMap[stateOrTerritory].toLocaleLowerCase();
      return [...(loadedCivicInfo.stateReps as any)[stateCode]];
    } else if (question === GOVERNOR_QUESTION) {
      if (noRepresentationTerritories.includes(stateOrTerritory)) {
        return ["You have no representation in the Senate"];
      }
      if (stateOrTerritory === "N/A") {
        return ["Please select a state or territory"];
      }
      const stateCode = stateMap[stateOrTerritory].toLocaleLowerCase();
      return [(loadedCivicInfo.stateGovernors as any)[stateCode]];
    } else if (question === CAPITAL_QUESTION) {
      return [(loadedCivicInfo.capitals as any)[stateOrTerritory]];
    } else if (question === PRESIDENT_QUESTION) {
      return [loadedCivicInfo.president];
    } else if (question === VP_QUESTION) {
      return [loadedCivicInfo.vicePresident];
    } else if (question === JUSTIC_QUESTION) {
      return [loadedCivicInfo.justices + ""];
    } else if (question === CHIEF_JUSTICE_QUESTION) {
      return [loadedCivicInfo.chiefJustice];
    } else if (question === PARTY_QUESTION) {
      return [loadedCivicInfo.presidentParty];
    } else if (question === SPEAKER_QUESTION) {
      return [loadedCivicInfo.speaker];
    } else {
      return currentQ.answers;
    }
  };

  const handleStateChange = (e: string) => {
    setStateOrTerritory(e);
    localStorage.setItem("savedState", e);
    if (Cookies.get('CitizenTestPrepCookieConsent') === 'true' && gaInitialized) {
    ReactGA.event({
      category: 'User',
      action: 'Selected State',
      label: e,
    });
  }
  };

  return (
    <div className="App">
      <div className="d-flex flex-column align-items-center">
      <div className="text-center mt-5">
        <h1 className="display-4">U.S. Citizenship Test Prep</h1>
        <p className="lead">Civic Questions</p>
      </div>
      <div className="card">
      <div className="d-flex justify-content-center">
      {/* <label htmlFor="state-selector">Select your state or territory for more accurate answers.</label> */}
      <div className="input-group mb-3 state-input">
        <div className="input-group-prepend">
          <span className="input-group-text" id="basic-addon1">
            State
          </span>
        </div>
        <select
          value={stateOrTerritory}
          onChange={(e) => handleStateChange(e.target.value)}
        id="state-selector">
          <option key={"N/A"} value={"N/A"}>
            {"N/A"}
          </option>

          {Object.keys(civicInfo.capitals).map((stateString) => (
            <option key={stateString} value={stateString}>
              {stateString}
            </option>
          ))}
        </select>
      </div>
      </div>
      <small id="stateHelp" className="form-text text-muted">Select your state or territory for more accurate answers.</small>
      </div>
      
      <div className="card">
      <div className="question">{currentQuestion?.question}</div>
      {reveal && (
        <div className="answers">
          {/* <ul className="list-group multi-column"> */}
          <ul className="list-group">
            {getAnswersForQuestion(currentQuestion).map((answer) => (
              <li className="list-group-item answer">
                {answer.charAt(0).toUpperCase() + answer.slice(1)}
              </li>
              // <div className="answer"></div>
            ))}
          </ul>
        </div>
      )}
      {(reveal && (
        <button
          onClick={handleReveal}
          className="reveal-button btn btn-primary"
          disabled
        >
          Reveal Answer
        </button>
      )) || (
        <button
          onClick={handleReveal}
          className="reveal-button btn btn-primary"
        >
          Reveal Answer
        </button>
      )}
      {reveal && (
        <div>
          <p className="answer-prompt">How well did you know the answer?</p>
          <button
            onClick={() => handleScore(5)}
            className="score-button btn btn-success"
          >
            Too easy
          </button>
          <button
            onClick={() => handleScore(4)}
            className="score-button btn btn-primary "
          >
            Correct
          </button>
          <button
            onClick={() => handleScore(3)}
            className="score-button btn btn-info"
          >
            Almost
          </button>
          <button
            onClick={() => handleScore(2)}
            className="score-button btn btn-warning"
          >
            Wrong
          </button>
          <button
            onClick={() => handleScore(1)}
            className="score-button btn btn-danger"
          >
            No Clue
          </button>
        </div>
      )}
    </div>
    </div>
    <CookieConsent
        location="bottom"
        buttonText="I understand"
        cookieName="CitizenTestPrepCookieConsent"
        style={{ background: "#2B373B" }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}
        onAccept={() => {
          if (Cookies.get('CitizenTestPrepCookieConsent') === 'true') {
            ReactGA.initialize('G-0TZ86H2EP1');
            setGaInitialized(true);
          }
        }}
      >
        This website uses cookies to enhance the user experience.
      </CookieConsent>
    </div>
  );
}

export default App;

// VULNS
// deploy
// Domain
// CDN
// Add buy me a coffee button
// SEO
// Ads?
